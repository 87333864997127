import React from "react";
import { Link } from 'gatsby';
import Layout from "../components/Layout";
import '../styles/global.css';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import RecruitmentLetter from '../files/BASIC_Cognitive_letter_phone_recruitment_2020.pdf';
import '../styles/global.css';
import Grid from '@mui/material/Grid';
import { StaticImage } from "gatsby-plugin-image";

const HomepageAlert = () => 
<Alert severity="info">
  <AlertTitle>IMPORTANT UPDATE: BASIC Cognitive Moves to Phone-only Contact</AlertTitle>
  BASIC Cognitive has temporarily suspended in-person contact with the community and instead 
  will use the phone for all contact. If you have received a <a href={RecruitmentLetter} target='_blank' rel='noopener noreferrer' className='callLink'>letter</a> from 
  our study or would like more information, please contact us at <a href="tel:3618261564" className='callLink'>(361)826-1564</a> or 
  send us an e-mail at <a href='mailto:umhs-basiccognitive@med.umich.edu?subject=Request%20information%20about%20Basic%20Cognitive'  target="_blank" rel="noopener noreferrer" className='callLink'>umhs-basiccognitive@med.umich.edu</a>. 
  You can also read our Frequently Asked Questions. We want to reassure you that we are NOT soliciting. The participation of eligible residents 
  is completely voluntary and will be done entirely by phone at this time.
</Alert>
export default function Home() {
  return (
    <Layout>
      <div>
      <h1 style={{color:'#3399f3', marginBottom:'30px'}}>Welcome to our study!</h1>
      <Grid container spacing={5} justifyContent="center" alignItems="center" className='homePageContent'>
        <Grid item xs={12} md={6}>
          <StaticImage src="../images/family_photo.jpg" height={450} />
        </Grid>
        <Grid item xs={12} md={6} style={{alignSelf:'flex-start', marginTop: '5%'}}>
           <h2 classname='welcomePageHeader'>Memory and Thinking</h2>
           <p className='welcomePageContent'>BASIC Cognitive is a research study being conducted to learn about how memory 
             and thinking changes as Nueces County residents age. We also hope to learn about 
             the experiences of caregivers or the people who help, like family members, neighbors, 
             and friends. We want to understand how their needs are being met and what areas 
             may require more attention.</p>
        </Grid>
        <Grid item xs={12} md={6}>
           <h2 classname='welcomePageHeader'>Partnership</h2>
           <p className='welcomePageContent'>We are continuing a long-term partnership with 
             the <a href='https://www.cctexas.com/health' target='_blank' rel='noopener noreferrer' className='callLink'>Corpus Christi-Nueces County Public Health District</a> 
             . We have also partnered with <a href='https://www.tamucc.edu/science/index.html' target='_blank' rel='noopener noreferrer' className='callLink'>Texas A&M Corpus Christi College of Science.</a></p>
           <p className='welcomePageContent'>The University of Michigan has been conducting research in the Corpus Christi area under study 
             leader Dr. Lewis Morgenstern since 1999. Many local residents have participated in our research 
             which has resulted in many important scientific discoveries.</p>  
        </Grid>
        <Grid item xs={12} md={6}>
          <iframe 
            className='responsiveIframe'
            width="750" height="450" 
            src="https://www.youtube.com/embed/SojFNSAiQY4" 
            title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen>
          </iframe>
        </Grid>
        <Grid item xs={12} md={6}>
          <StaticImage src="../images/new_staff_photo.jpg" height={450} />
          <p style={{fontSize:'0.7em'}}>BASIC Cognitive staff pictured at press conference on January 14, 2020. Pictured from left to right, 
            top to bottom: Dr. Lewis Morgenstern, Noemi Ybarra, Dr. Kim Onufrak, Denisse Martinez, Mercedes, Fonseca, 
            Leslie Martinez, Hanna Davis, Nelda Garcia, Judge Barbara Canales, Ruth Longoria, Irma Mireles, Deidre Acosta, 
            Alexis Enriquez, Elisa Silva</p>
        </Grid>
        <Grid item xs={12} md={6} style={{marginTop: '-5%'}}>
          <h2 classname='welcomePageHeader'>Participation (as of April 2023, new enrollment has paused)</h2>
          <p className='welcomePageContent'>We are currently recruiting participants for our study. To find out more click <a href="Recruitment Flyer New.pdf" className='callLink' target="_blank">here</a>. 
          Our project has only been successful due to the significant contribution from many Nueces County residents who have participated in the study. We hope that you will 
          also decide to participate. After we completely remove all names and any identifying information, the data collected as part of our study will be used by researchers, 
          decision-makers, and government leaders to create policies that improve all our lives.</p>
          <p className='welcomePageContent'>For additional information contact BASIC Cognitive Field Office. at <a href="tel:3612571239" className='callLink'>361-257-1239</a> or read 
            our <Link to='/fAQs' className='callLink' target="_blank">Frequently Asked Questions.</Link></p>  
        </Grid>
      </Grid>
      <h2 style={{color:'#3399f3'}}>Learn more</h2> 
      <ul style={{fontSize: '1.3rem'}}>
        <li><Link to='/news' className='callLink'>News Stories about BASIC Cognitive</Link></li> 
        <li><Link to='/resourceLinks' className='callLink'>Resource Links for BASIC Cognitive participants</Link></li>
        <li><Link to='/meetOurTeam' className='callLink'>Meet our BASIC Cognitive Team</Link></li>
        <li><Link to='/contact' className='callLink'>Contacting BASIC Cognitive</Link></li>
      </ul> 
      </div>
    </Layout>
  )
}
